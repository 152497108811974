$font-size-large: 1.5rem;
$font-size-med-large: 1.4rem;
$font-size-med: 1.25rem;
$font-size-small-med: 1.15rem;
$font-size-small: 1.05rem;
$font-size-smallest: 0.9375rem;

$box-shadow: 0 0 8px #00000036;

$guess-fill-time: 1.5s;
$text-appear-time: 0.5s;
$text-appear-delay: $guess-fill-time + 0.2s;
$border-cell-flip-time: 0.75s;
$border-cell-flip-delay: $text-appear-delay + $text-appear-time;
$symbol-flip-time: 1s;
$symbol-flip-delay: $border-cell-flip-delay + 0.75s;

@mixin flex($jc: center, $ai: center) {
    display: flex;
    justify-content: $jc;
    align-items: $ai;
}

:root {
    --app-background: #ffffff;
    --app-title-highlight: #388bc3;
    --app-clr-0: #e6e6e6;
    --app-clr-1: #d7d7d7;
    --app-clr-2: #b9b9b9;
    --app-clr-3: #afafaf;
    --app-clr-4: #7e7e7e;
    --text-primary: #000000;
    --checked: #2aaccd;
    --link-unvisited: #0066cc;
    --link-visited: #551a8b;
    --small-modal-overlay: #ffffff73;
    --result-info-tile: #f0f0f0;
    --result-info-wiki: #e3e3e3;
    --share: #56c2dd;
    --share-faded: #75b9ca;
    --blank-guess: #f2f2f2;
    --guess: #dadada;
    --correct-guess: #89d180;
    --border-guess: #8fb6d8;
    --continent-guess: #dbd091;
    --incorrect-guess: #b4b6b9;

    --correct-guess: #7dcc73;
    --border-guess: #81add3;
    --continent-guess: #d6ca83;
    --incorrect-guess: #aaacb0;
}

[data-theme="dark"] {
    --app-background: #1b1b1b;
    --app-title-highlight: #4d99cc;
    --app-clr-0: #cfcfcf;
    --app-clr-1: #c1c1c1;
    --app-clr-2: #a6a6a6;
    --app-clr-3: #9d9d9d;
    --app-clr-4: #717171;
    --text-primary: #ffffff;
    --link-unvisited: #5cadff;
    --link-visited: #bc8be9;
    --small-modal-overlay: #00000073;
    --result-info-tile: #464545;
    --result-info-wiki: #626060;
    --share: #1e8199;
    --share-faded: #2d6d7b;
    --blank-guess: #252525;
    --guess: #3d3d3d;
    --correct-guess: #72c867;
    --border-guess: #74a4ce;
    --continent-guess: #d2c475;
    --incorrect-guess: #a1a3a7;

    .page-header-content-container, .modal-header, .modal-separator {
        border-bottom: 2px solid var(--app-clr-4);
    }

    .carousel-prev, 
    .carousel-next,
    .help-modal-hint-icon,
    .menu-container > button, 
    .modal-exit-button,
    .border-container,
    .coa-front,
    .flag-front {
        -webkit-filter: invert(1);
                filter: invert(1);
    }

    .symbol-front-dark {
        -webkit-filter: invert(0);
                filter: invert(0);
    }

    .guess-descriptive-icon {
        -webkit-filter: invert(0.75);
                filter: invert(0.75);
    }
    
    .country, .distribution-bar {
        color: #000000;
    }

    .country-list-container {
        background-color: var(--app-clr-4);
    }

    .contact-button, 
    .collapsible-button,
    .country,
    .distribution-bar {
        background-color: var(--app-clr-2); 
    }

    .stats-modal-data-point-label {
        color: var(--app-clr-3);
    }

    .settings-option-description-subtitle {
        color: var(--app-clr-3);
    }

    .result-modal, .achievement-notification {
        background-color: #222222;
    }
}

*, ::before, ::after {
    box-sizing: border-box;
    font-family: 'League Spartan';
}

html, body {
    margin: 0;
    padding: 0;
}

p, 
div, 
span,
input, 
button {
    color: var(--text-primary);
    line-height: 1.2;
}

input {
    border: none;
    outline: none;
}

.app-container {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
    @include flex($jc: flex-start);
    flex-flow: column nowrap;
    text-align: center;
    background-color: var(--app-background);
    overflow-y: auto;
}

.page-column-container {
    width: 100%;
    height: 100%;
    max-width: min(95vw, 500px);
    margin-top: min(1vw, 8px);
    @include flex($jc: flex-start, $ai: center);
    flex-flow: column nowrap;
}

.page-header {
    width: 100%;
    height: 50px;
    min-height: 50px;
    @include flex;
    z-index: 2;
}

.page-header-content-container {
    width: 100%;
    height: 100%;
    min-width: 300px;
    @include flex($jc: space-between, $ai: center);
    border-bottom: 2px solid var(--app-clr-1);
}

.menu-container {
    width: 60px;
    height: 40px;
}

.menu-container > button {
    width: 100%;
    height: 40px;
    margin: 0 3px;      
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.menu-container.left {
    @include flex($jc: space-around, $ai: center);
}

.app-title {
    font-family: 'Lilita One';
    font-size: 2rem;
    pointer-events: none;
    text-transform: uppercase;
}

.app-title-highlight {
    font-family: 'Lilita One';
    color: var(--app-title-highlight);
}

.app-title-small {
    font-family: 'Lilita One';
    font-size: $font-size-small;
    pointer-events: none;
    text-transform: uppercase;
}

.menu-container.right {
    @include flex($jc: space-around, $ai: center);
}

.hints-container {
    width: 100%;
    margin-top: 1.5rem;
    @include flex;
}

.border-container {
    width: 154px;
    height: 154px;
    position: relative;
    z-index: 2;
}

.border-container-front {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    opacity: 1;
    transition: opacity 1s 2.5s ease;
    pointer-events: none;
}

.border-container-back {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    opacity: 0;
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 1s 2.5s ease;
    pointer-events: none;
}

.border-cell-container {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
}

.border-cell-reveal {
    -webkit-animation: border-cell-flip $border-cell-flip-time $border-cell-flip-delay ease forwards;
            animation: border-cell-flip $border-cell-flip-time $border-cell-flip-delay ease forwards;
}

.border-cell-container > div {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.border-cell-front {
    background-color: var(--app-clr-1);
}

.border-cell-back {
    background-color: var(--app-clr-1);
    background-size: 100%;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
}

.symbol-flippable-container {
    width: min(20vw, 80px);
    height: min(20vw, 80px);
    border-radius: 3px;
    background-color: transparent;
    position: relative;
    z-index: 2;
}

.symbol-flippable-container[data-type="coa"] {
    margin-right: min(5vw, 2rem);
}

.symbol-flippable-container[data-type="flag"] {
    margin-left: min(5vw, 2rem);
}

.symbol-flippable-container-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    // -moz-backface-visibility: hidden;
}

.symbol-flip-reveal {
    -webkit-animation: symbol-flip $symbol-flip-time $symbol-flip-delay ease forwards;
            animation: symbol-flip $symbol-flip-time $symbol-flip-delay ease forwards;
}

.symbol-flippable-container-inner > div {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.coa-front {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65% 65%;
    transform: rotateX(0deg); // Fix backface-visibility bug in FF
}

.coa-back {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotateX(180deg);
    cursor: pointer;
}

.flag-front {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65% 65%;
    transform: rotateX(0deg); // Fix backface-visibility bug in FF
}

.flag-back {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotateX(180deg);
    cursor: pointer;
}

.user-input-container {
    width: 100%;
    min-height: 250px;
    margin-top: 25px;
}

.guesses-container {
    width: 100%;
    @include flex($jc: flex-start, $ai: center);
    flex-flow: column nowrap;
    position: relative;
}

.guess {
    width: 100%;
    height: 38px;
    margin-bottom: 5px;
    // position: relative;
    @include flex;
    font-size: 1.1875rem;
    border: none;
    border-radius: 5px;
    background-color: var(--blank-guess);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    transform: translateZ(0);
}

.fill-bar {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    @include flex;
    position: relative;
    flex-flow: column nowrap;
    transform: translateX(-100%);
    background-color: var(--guess);
    border: none;
    border-radius: 5px;
    -webkit-animation: eval-fill $guess-fill-time ease-in-out forwards;
            animation: eval-fill $guess-fill-time ease-in-out forwards;
}

.staggered-circles {
    width: 100px;
    height: 100%;
    position: absolute;
    @include flex;
    z-index: 5;
}

.circle {
    // 13,146
    width: 13px;
    height: 13px;
    margin: 0 5px;
    background-color: #6b6b6b;
    border-radius: 50%;
    transform: scale(0) rotate3d(0, 0, 1, 0deg);
    z-index: 1;
    -webkit-animation: stagger 1s ease-in-out forwards;
            animation: stagger 1s ease-in-out forwards;
}

.circle--correct {
    background-color: #1aad23;
}

.circle--border {
    background-color: #0097c3;
}

.circle--continent {
    background-color: #ea9800;
}

.circle--incorrect {
    background-color: #c4130b;
}

.circle:first-child {
    -webkit-animation-delay: 500ms;
            animation-delay: 500ms;
}

.circle:nth-child(2) {
    -webkit-animation-delay: 750ms;
            animation-delay: 750ms;
}

.circle:last-child {
    -webkit-animation-delay: 1000ms;
            animation-delay: 1000ms;
}

.guess-text {
    width: 100%;
    @include flex;
    opacity: 0;
    -webkit-animation: text-appear $text-appear-time $text-appear-delay ease-in-out forwards;
            animation: text-appear $text-appear-time $text-appear-delay ease-in-out forwards;
}

.guess-text-inner {
    width: 100%;
    height: 100%;
    @include flex;
    flex-flow: row nowrap;
}

.guess-text-country-container {
    width: 100%;
    margin: 0 5px;
    min-width: 0;
    @include flex($jc: flex-start);
}

.guess-text-country-container > svg {
    margin: 2px;
}

.guess-descriptive-icon {
    -webkit-filter: invert(0.35);
            filter: invert(0.35);
}

.guess-text-country {
    margin: 3px 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.guess-text-hints {
    margin: 0 7px;
    @include flex($jc: flex-end);
}

.guess-text-hints > svg {
    margin: 2px;
    width: 24px;
    height: 24px;
}

.text-input-container {
    width: 100%;
    margin: 1rem 0 2rem;
    position: relative;
    border-bottom: 2px solid var(--app-clr-2);
}

.enter-button {
    padding: 0.5rem;
    border: none;
    background-color: var(--app-clr-1);
    color: #000000;
    border-radius: 5px;
    font-size: $font-size-smallest;
    text-transform: uppercase;
    cursor: pointer;
}

.enter-button:hover {
    -webkit-filter: brightness(1.05);
            filter: brightness(1.05);
}

.enter-button:active {
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
}

.text-input {
    width: 100%;
    height: 50px;
    font-size: $font-size-med;
    background-color: transparent;
    text-align: left;
}

.text-input::-webkit-input-placeholder {
    color: var(--app-clr-4);
    opacity: 1;
}

.text-input::-moz-placeholder {
    color: var(--app-clr-4);
    opacity: 1;
}

.text-input:-ms-input-placeholder {
    color: var(--app-clr-4);
    opacity: 1;
}

.text-input::-ms-input-placeholder {
    color: var(--app-clr-4);
    opacity: 1;
}

.text-input::placeholder, .text-input:disabled {
    color: var(--app-clr-4);
    opacity: 1;
}

// .tooltip {
//     height: 30px;
//     position: absolute;
//     opacity: 0;
//     padding: 14px;
//     background-color: #0c0e0e;
//     @include flex($jc: flex-start);
//     white-space: nowrap;
//     transition: opacity 0.4s ease;
//     border-radius: 5px;
//     color: #ffffff;
// }

// .tooltip::before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     margin-top: -5px;
//     border: 5px solid transparent;
// }

.country-not-found {
    height: 30px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    @include flex;
    font-size: $font-size-small;
    font-weight: 700;
    text-transform: uppercase;
    flex-flow: column nowrap;
    white-space: nowrap;
    background-color: #9d2020;
    border-radius: 1rem;
    color: #ffffff;
    z-index: 2;
}

.country-not-found-transition-enter, .country-not-found-transition-appear {
    opacity: 0;
}

.country-not-found-transition-enter-active, .country-not-found-transition-appear-active {
    opacity: 1;
    transition: opacity 0.4s ease-out;
}

.country-not-found-transition-exit {
    opacity: 1;
}

.country-not-found-transition-exit-active {
    opacity: 0;
    transition: opacity 0.4s ease-out;
}

.country-list-container {
    width: 100%;
    max-height: 280px;
    position: absolute;
    margin-top: 1rem;
    @include flex;
    flex-flow: row wrap;
    background-color: var(--app-clr-2);
    border-radius: 5px;
    overflow-y: scroll;
    z-index: 2;
}

.country {
    width: 100%;
    margin: 5px 10px;
    padding: 10px;
    font-size: $font-size-med;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--app-clr-1);
    border: none;
    text-align: start;
}

.country:hover {
    -webkit-filter: brightness(1.05);
            filter: brightness(1.05);
}

.country:first-child {
    margin-top: 10px;
}

.country:last-child {
    margin-bottom: 10px;
}

.country > span {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.modal-overlay {
    position: fixed;
    inset: 0;
    @include flex;
    flex-flow: column nowrap;
    background-color: var(--app-background);
    z-index: 999;
}

.modal-overlay-transition-enter, .modal-overlay-transition-appear {
    opacity: 0;
}

.modal-overlay-transition-enter-active, .modal-overlay-transition-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-out;
}

.modal-overlay-transition-exit {
    opacity: 1;
}

.modal-overlay-transition-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease-out;
}

.modal {
    max-width: 500px;
    height: 100%;
    padding: 10px;
    position: absolute;
    overflow-y: auto;
}

.modal-transition-enter, .modal-transition-appear {
    transform: translateY(5%);
    opacity: 0;
}

.modal-transition-enter-active, .modal-transition-appear-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}

.modal-transition-exit {
    transform: translateY(0);
    opacity: 1;
}

.modal-transition-exit-active {
    transform: translateY(5%);
    opacity: 0;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}

.modal-header {
    width: 100%;
    height: 40px;
    position: relative;
    @include flex;
    border-bottom: 2px solid var(--app-clr-1);
}

.modal-separator {
    width: 100%;
    border-bottom: 2px solid var(--app-clr-1);
}

.modal-title {
    font-size: $font-size-large;
    font-weight: 700;
}

.modal-exit-button {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    @include flex;
    padding: 0;
    border: 0;
    background-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.modal-exit-icon {
    @include flex;   
    width: 30px;
    height: 30px;
}

.modal-content {
    width: 100%;
    font-size: $font-size-small;
    margin-top: 1rem;
}

.modal-content a {
    color: var(--link-unvisited);
}

.carousel-container {
    width: 100%;
    @include flex;
    flex-flow: column nowrap;
}

.carousel-item {
    width: 100%;
    height: 165px;
    margin-bottom: 8px;
}

.carousel-item-eval-container {
    margin-top: 1rem;
    @include flex($ai: flex-start);
    flex-flow: column nowrap;
}

.carousel-item-eval {
    @include flex;
    margin: 4px;
    text-align: left;
}

.carousel-item-eval-transition-enter, .carousel-item-eval-transition-appear {
    opacity: 0;
}

.carousel-item-eval-transition-enter-active, .carousel-item-eval-transition-appear-active {
    opacity: 1;
    transition: opacity 1s ease;
}

.carousel-item-eval-transition-exit {
    opacity: 1;
}

.carousel-item-eval-transition-exit-active {
    opacity: 0;
    transition: opacity 1s ease;
}

.carousel-item-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;
    transform: translateZ(0);
}

.carousel-markers-container {
    @include flex;
    margin: 0 1rem 1rem;
    z-index: 3;
}

.carousel-prev, .carousel-next {
    width: 35px;
    height: 35px;
    margin: 0 1rem;
    padding: 0;
    position: relative;
    @include flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.carousel-marker {
    width: 24px;
    height: 24px;
    margin: 0 5px;
    padding: 0;
    @include flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.help-modal-hints-container {
    margin: 1rem 0;
    @include flex($ai: flex-start);
    flex-flow: column nowrap;
    text-align: left;
}

.help-modal-hint {
    height: 40px;
    @include flex;
    margin: 1rem;
}

.help-modal-hint-icon {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    margin-right: 1rem;
    padding: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.small-modal-overlay-root {
    @extend .modal-overlay;
    background-color: var(--small-modal-overlay);
}

.small-modal-overlay-root-transition-enter, .small-modal-overlay-root-transition-appear {
    opacity: 0;
}

.small-modal-overlay-root-transition-enter-active, .small-modal-overlay-root-transition-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-out;
}

.small-modal-overlay-root-transition-exit {
    opacity: 1;
}

.small-modal-overlay-root-transition-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease-out;
}

.small-modal-overlay {
    background: transparent;
}

.small-modal {
    max-width: 400px;
    margin: 1rem;
    padding: 10px;
    background-color: var(--app-background);
    box-shadow: $box-shadow;
    overflow-y: auto;
    border-radius: 3px;
    line-height: 1;
}

.stats-modal-root {
    z-index: 1001;
}

.stats-modal-content {
    margin: 1rem;
    @include flex;
    flex-flow: column nowrap;
}

.stats-modal-data {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin-bottom: 1rem;
}

.stats-modal-data-point {
    @include flex;
    flex-flow: column nowrap;
}

.stats-modal-data-point-value {
    font-size: $font-size-med;
    font-weight: 700;
}

.stats-modal-data-point-label {
    color: var(--app-clr-4);
    margin-top: 3px;
}

.stats-modal-subheading {
    margin: 1rem 0;
    font-size: $font-size-med;
    font-weight: 700;
}

.performance-distribution-container {
    width: 100%;
    margin: 0.5rem 0 1rem;
    @include flex;
    flex-flow: column nowrap;
    font-size: $font-size-small;
}

.distribution-bar-container {
    width: 100%;
    margin: 3px;
    @include flex($jc: flex-start, $ai: center);
}

.distribution-bar-label {
    width: 25px;
    height: 30px;
    @include flex;
    font-weight: 700;
}

.distribution-bar {
    min-width: 20px;
    height: 30px;
    padding: 5px;
    @include flex($jc: flex-end, $ai: center);
    background-color: var(--app-clr-1);
}

.share-button-container {
    width: 100%;
    margin-top: 1rem;
    @include flex;
}

.share-button {
    width: 225px;
    padding: 15px;
    font-size: $font-size-med;
    font-weight: 700;
    background-color: var(--share);
    border: none;
    border-radius: 3px;
    transition: all 0.05s ease;
    cursor: pointer;
    line-height: 1;
}

.share-button:hover {
    -webkit-filter: brightness(1.05);
            filter: brightness(1.05);
}

.share-button:active {
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
}

.share-button-faded {
    background-color: var(--share-faded);
}

.achievement-notification {
    min-width: 280px;
    height: 90px;
    margin: 8px;
    position: absolute;
    @include flex;
    flex-flow: column nowrap;
    background-color: var(--app-background);
    box-shadow: $box-shadow;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: 700;
    line-height: 1;
    z-index: 3;
    cursor: pointer;
    transform-style: preserve-3d;
    overflow: auto;
}

.notification-transition-enter, .notification-transition-appear {
    opacity: 0;
}

.notification-transition-enter-active, .notification-transition-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.notification-transition-exit {
    opacity: 1;
}

.notification-transition-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.achievement-notification-title {
    margin-bottom: 8px;
}

.achievement-notification-icon-container {
    @include flex;
}

.achievement-notification-icon-container > img {
    width: 45px;
    height: 45px;
    margin: 0 3px;
}

.achievements-modal-content {
    margin: 1rem 1rem 0;
    @include flex;
}

.achievements-container {
    @include flex;
    flex-flow: row wrap;
}

.achievement {
    height: calc(125px + 1rem);
    margin: 8px 0;
    border: none;
    background-color: transparent;
    font-size: 1rem;
}

.achievement-icon-container {
    width: 125px;
    height: 125px;
    @include flex;
}

.achievement-icon {
    width: 100%;
    height: 100%;
    transform: scale3d(1, 1, 1);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.spinner-container {
    width: 100%;
    height: 100%;
    @include flex;
}

.spinner {
    height: 35px;
    width: 35px;
    background-color: transparent;
    border-radius: 50%;
    border: 5px solid var(--app-clr-4);
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    -webkit-animation: spin 1.25s linear infinite;
            animation: spin 1.25s linear infinite;
}

.grow {
    -webkit-animation: grow 2s ease-in-out infinite;
            animation: grow 2s ease-in-out infinite;
}

.achievement-info-modal {
    width: 300px;
}

.achievement-info-modal-content {
    margin: 8px;
}

.achievement-info-modal-header {
    border: none !important;
}

.achievement-info-modal-header > .modal-title {
    margin-top: 2px;
}

.achievement-info-container {
    @include flex;
    flex-flow: column nowrap;
}

.achievement-info-icon {
    width: 225px;
    height: 225px;
}

.achievement-info-description {
    font-size: $font-size-small-med;
}

.achievement-info-unlock-date {
    color: var(--app-clr-4);
}

.settings-content {
    margin: 0 1rem;
    @include flex;
    flex-flow: column wrap;
}

.settings-option {
    width: 100%;
    height: 60px;
    margin-bottom: 1em;
    @include flex($jc: space-between);
}

.settings-option-description {
    @include flex($jc: center, $ai: flex-start);
    flex-flow: column nowrap;
    text-align: left;
    margin-right: 5px;
}

.settings-option-description-title {
    font-size: $font-size-med;
}

.settings-option-description-subtitle {
    font-size: $font-size-small;
    color: var(--app-clr-4);
    margin-top: 3px;
}

.settings-option-control {
    @include flex;
    margin-left: 5px;
}

.slider-box {
    width: 40px;
    height: 25px;
    min-width: 40px;
    min-height: 25px;
    position: relative;
}

.input-checkbox {
    width: 0;
    height: 0;
    opacity: 0;
}

.slider-switch {
    position: absolute;
    inset: 0;
    background-color: var(--app-clr-3);
    transition: background-color 1s ease;
    border-radius: 3px;
    cursor: pointer;
}

.slider-switch::before {
    content: "";
    width: 17px;
    height: calc(100% - 6px);
    position: absolute;
    top: 3px;
    left: 3px;
    display: inline-block;
    background-color: #ffffff;
    transform: translateX(0%);
    transition: transform 0.25s ease;
    font-weight: 700;
    border-radius: 2px;
}

.input-checkbox:checked + .slider-switch {
    background-color: var(--checked);
}

.input-checkbox:checked + .slider-switch::before {
    transform: translateX(100%);
}

.contact-button {
    padding: 12px;
    font-size: $font-size-smallest;
    line-height: 1;
    border: none;
    border-radius: 5px;
    background-color: var(--app-clr-1);
    color: #000000;
    cursor: pointer;

    &:hover {
        -webkit-filter: brightness(1.05);
                filter: brightness(1.05);
    }

    &:active {
        -webkit-filter: brightness(0.95);
                filter: brightness(0.95);
    }
}

.collapsible {
    width: 100%;
    margin-top: 1rem;
}

.collapsible:first-child {
    margin-top: 2rem;
}

.collapsible-button {
    width: 100%;
    padding: 12px;
    position: relative;
    font-size: $font-size-small-med;
    border: none;
    text-align: left;
    border-radius: 5px;
    background-color: var(--app-clr-1);
    color: #000000;
    line-height: 1;
    cursor: pointer;
    @include flex($jc: space-between, $ai: center);
}

.collapsible-button > svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    margin-left: 5px;
}

.collapsible-content {
    max-height: 200px;
    overflow-y: auto;
}

.collapsible-content > p {
    margin: 10px 0 0;
    padding: 10px;
    text-align: left;
    line-height: 1.2;
}

.collapsible-content a {
    color: var(--link-unvisited);
}

.symbol-modal-root {
    z-index: 999;
}

.symbol-modal-overlay {
    @include flex($jc: flex-start);
    flex-flow: column nowrap;
    background-color: transparent;
}

.symbol-modal {
    @include flex;
    flex-flow: column nowrap;
}

.symbol-modal-header {
    width: 100%;
    height: 30px;
    position: relative;
    @include flex;
}

.symbol-modal-content {
    width: min(85vw, 375px);
    height: min(45vh, 375px);
    padding: 1rem;
    position: relative;
    @include flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
}

.symbol-modal-title {
    font-size: $font-size-small-med;
    font-weight: 700;
    @include flex;
    flex-flow: column nowrap;
}

.symbol-subtitle {
    position: absolute;
    top: 0;
}

.result-modal {
    width: min(350px, 95%);
    max-height: min(700px, 90%);
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    z-index: 3;
}

.result-modal-transition-enter, .result-modal-transition-appear {
    opacity: 0;
}

.result-modal-transition-enter-active, .result-modal-transition-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
}

.result-modal-transition-exit {
    opacity: 1;
}

.result-modal-transition-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}

.result-modal-header {
    width: 100%;
    height: 50px;
    position: relative;
    @include flex;
}

.result-modal-title {
    display: inline-block;
    max-width: 225px;
    font-size: $font-size-large;
    font-weight: 700;
    overflow: auto;
    white-space: nowrap;
    // text-overflow: ellipsis;

    // font-family: 'Lilita One';
    // text-transform: uppercase;
    // letter-spacing: 2px;
}

.result-modal-content {
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
}

.result-info {
    padding: 5px;
    font-size: $font-size-small;
    @include flex($jc: flex-start, $ai: flex-start);
    flex-flow: column nowrap;
    background-color: var(--result-info-tile);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.result-info:nth-child(3), 
.result-info:nth-child(4),
.result-info:nth-child(5) {
    grid-column: span 2;
}

.result-info-title {
    margin: 12px 0 12px 8px;
    @include flex;
    font-size: $font-size-med;
}

.result-info-value {
    margin: 0 0 8px 8px;
    text-align: left;
    word-wrap: break-word;
    z-index: 1;
}

.result-info-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -1.35rem;
    right: -1rem;
    // background-color: rgba(128, 128, 128, 0.26);
    // border-radius: 50%;
    opacity: 0.55;
}

.result-info:first-child > .result-info-icon {
    bottom: -1.5rem;
    right: -1.25rem;
}

.result-info-pin {
    width: 45px;
    height: 45px;
    margin: -8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-animation: squish 6s 0s ease infinite;
            animation: squish 6s 0s ease infinite;
}

.result-info-wiki {
    width: 100%;
    height: 30px;
    grid-column: span 2;
    @include flex;
    background-color: var(--result-info-wiki);
    font-size: $font-size-small;
    color: var(--text-primary);  
    text-decoration: none;
    text-align: center;
    border: 0;
    border-radius: 30px;
    cursor: pointer;
}

.result-info-wiki:hover {
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
}

.copy-button-notification {
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    height: 25px;
    opacity: 0;
    @include flex;
}

.copy-button-notification[aria-hidden="true"] {
    display: none;
}

.copy-button-notification[aria-hidden="false"] {
    display: block;
}

.copied-transition {
    -webkit-animation: copied 1.15s ease;
            animation: copied 1.15s ease;
}

@-webkit-keyframes eval-fill {
    100% {
        transform: translateX(0) translateZ(0);;
    }
}

@keyframes eval-fill {
    100% {
        transform: translateX(0) translateZ(0);;
    }
}

@-webkit-keyframes stagger {
    20%, 100% {
        transform: scale(0) rotate3d(0, 0, 1, 180deg);
    }
    50% {
        transform: scale(1);
    }
}

@keyframes stagger {
    20%, 100% {
        transform: scale(0) rotate3d(0, 0, 1, 180deg);
    }
    50% {
        transform: scale(1);
    }
}

@-webkit-keyframes text-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes text-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes border-cell-flip {
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes border-cell-flip {
    100% {
        transform: rotateY(180deg);
    }
}

@-webkit-keyframes symbol-flip {
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes symbol-flip {
    100% {
        transform: rotateX(180deg);
    }
}

@-webkit-keyframes modal-appear {
    0% {
        transform: translateY(5%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes modal-appear {
    0% {
        transform: translateY(5%);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes squish {
    0%, 83%, 100% { 
        transform: scale(1, 1); 
    }
    89% { 
        transform: scale(0.8, 1.1); 
    }
    93% { 
        transform: scale(1.1, 0.8); 
    }
    97% { 
        transform: scale(0.95, 1.05); 
    }
}

@keyframes squish {
    0%, 83%, 100% { 
        transform: scale(1, 1); 
    }
    89% { 
        transform: scale(0.8, 1.1); 
    }
    93% { 
        transform: scale(1.1, 0.8); 
    }
    97% { 
        transform: scale(0.95, 1.05); 
    }
}

@-webkit-keyframes copied {
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-25px);
    }
}

@keyframes copied {
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-25px);
    }
}

@-webkit-keyframes grow {
    0%, 100% {
        transform: scale3d(1.075, 1.075, 1);
    }
    50% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes grow {
    0%, 100% {
        transform: scale3d(1.075, 1.075, 1);
    }
    50% {
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 480px) {
    .app-title {
        // font-size: 1.9rem;
        font-size: 1.7rem;
    }
}

@media (max-width: 360px) {
    .app-title {
        // font-size: 1.7rem;
        font-size: 1.6rem;
    }

    .help-modal-hint {
        margin: 1.5rem;
    }

    .border-container {
        width: 130px;
        height: 130px;
    }

    .guess, .text-input, .country {
        font-size: $font-size-small-med;
    }

    .country-not-found, .collapsible-button {
        font-size: $font-size-small;
    }
}

@media (max-width: 320px) {
    .app-title {
        font-size: 1.5rem;
    }
}